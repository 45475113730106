.access-denied-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 2rem;
}

.access-denied-card {
    background: white;
    border-radius: 12px;
    padding: 2.5rem;
    text-align: center;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.access-denied-icon {
    background: #fff3cd;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    color: #856404;
}

.access-denied-title {
    color: #dc3545;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.access-denied-text {
    color: #6c757d;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.access-denied-actions {
    display: flex;
    justify-content: center;
}

.access-denied-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.access-denied-button.primary {
    background: #0d6efd;
    color: white;
}

.access-denied-button.primary:hover {
    background: #0b5ed7;
    transform: translateY(-1px);
}
