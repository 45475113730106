.account-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.account-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
}

.account-title {
    color: #2c3e50;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.account-subtitle {
    color: #7f8c8d;
    font-size: 1rem;
}

.account-section {
    background: white;
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.section-title {
    color: #2c3e50;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.section-title i {
    color: #3498db;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.info-item {
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    word-break: break-word;
    overflow-wrap: break-word;
}

.info-label {
    color: #6c757d;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.info-value {
    color: #2c3e50;
    font-weight: 500;
    word-break: break-word;
    overflow-wrap: break-word;
    min-width: 0;
    width: 100%;
}

.account-actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.action-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
}

.primary-button {
    background: #3498db;
    color: white;
}

.primary-button:hover {
    background: #2980b9;
}

.danger-button {
    background: #e74c3c;
    color: white;
}

.danger-button:hover {
    background: #c0392b;
}

.secondary-button {
    background: #f8f9fa;
    color: #2c3e50;
    border: 1px solid #dee2e6;
}

.secondary-button:hover {
    background: #e9ecef;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .toggle-slider {
    background-color: #3498db;
}

input:checked + .toggle-slider:before {
    transform: translateX(26px);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-title {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .account-container {
        padding: 1rem;
    }
    
    .account-section {
        padding: 1.5rem;
    }
    
    .info-grid {
        grid-template-columns: 1fr;
    }
    
    .account-actions {
        flex-direction: column;
    }
    
    .action-button {
        width: 100%;
    }
}
