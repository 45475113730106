.about-section {
    padding: 6rem 0;
    background: linear-gradient(to bottom, #ffffff, var(--light-bg));
}

.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.about-header {
    text-align: center;
    margin-bottom: 4rem;
}

.about-header h1 {
    font-size: 3.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
}

.about-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    margin-bottom: 4rem;
}

.about-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(11, 94, 215, 0.1);
}

.about-card h2 {
    color: var(--secondary-color);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.about-card p {
    color: #666;
    line-height: 1.6;
}

.about-list {
    list-style: none;
    padding: 0;
}

.about-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: #444;
}

.about-list li svg {
    margin-right: 1rem;
    color: var(--primary-color);
}

.about-team {
    text-align: center;
    margin-top: 4rem;
}

.about-team h2 {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 2rem;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

.team-member {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.team-member:hover {
    transform: translateY(-5px);
}

.team-member img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
}

.team-member h3 {
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
}

.team-member p {
    color: #666;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .about-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .team-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .about-header h1 {
        font-size: 2.5rem;
    }
}
