.no-subscription-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    padding: 2rem;
}

.no-subscription-card {
    background: white;
    border-radius: 12px;
    padding: 2.5rem;
    text-align: center;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.no-subscription-card:hover {
    transform: translateY(-2px);
}

.no-subscription-icon {
    background: #f8f9fa;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    color: #6c757d;
}

.no-subscription-title {
    color: #2c3e50;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.no-subscription-text {
    color: #6c757d;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.no-subscription-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.no-subscription-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.2s ease;
}

.no-subscription-button.primary {
    background: #0d6efd;
    color: white;
}

.no-subscription-button.primary:hover {
    background: #0b5ed7;
    transform: translateY(-1px);
}

.no-subscription-button.secondary {
    background: transparent;
    color: #6c757d;
    border: 1px solid #dee2e6;
}

.no-subscription-button.secondary:hover {
    background: #f8f9fa;
    color: #495057;
    border-color: #ced4da;
}
