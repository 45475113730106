/* Base styles */
:root {
    --primary-color: #0b5ed7;
    --secondary-color: #2c3e50;
    --accent-color: #e74c3c;
    --light-bg: #f8f9fa;
    --dark-bg: #2c3e50;
}

.homepage {
    overflow-x: hidden;
    position: relative;
}

/* Hero Section */
.hero {
    min-height: 100vh;
    background: linear-gradient(135deg, var(--dark-bg), var(--secondary-color));
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4rem 1rem;
    position: relative;
}

/* Hero Content Glass Effect */
@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.hero-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        120deg,
        transparent 20%,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 30%
    );
    animation: shimmer 5s infinite linear;
    background-size: 200% 100%;
    pointer-events: none;
}



@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hero h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    color: var(--dark-bg);
    position: relative;
    z-index: 1;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    opacity: 0.9;
    color: var(--dark-bg);
    position: relative;
    z-index: 1;
}

.hero-cta {
    display: flex;
    gap: 1rem;
    justify-content: center;
    position: relative;
    z-index: 1;
}

/* Features Section */
.features {
    padding: 6rem 2rem;
    background: var(--light-bg);
}

.section-header {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 4rem;
    position: relative;
    overflow: hidden;
}

.section-header h2 {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
}

.section-header h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 3px;
    background: var(--primary-color);
    transition: width 0.4s ease;
}

.section-header:hover h2::after {
    width: 100%;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    overflow: hidden;
}

.feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        135deg,
        rgba(13, 110, 253, 0.1),
        rgba(255, 255, 255, 0.1),
        rgba(13, 110, 253, 0.1)
    );
    opacity: 0;
    transition: opacity 0.4s ease;
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.feature-card:hover::before {
    opacity: 1;
}

.feature-icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    font-size: 2rem;
    color: var(--primary-color);
    background: var(--light-bg);
    border-radius: 16px;
    transition: all 0.4s ease;
    position: relative;
}

.feature-card:hover .feature-icon {
    transform: rotateY(180deg);
    background: var(--primary-color);
    color: white;
}

.feature-card h3 {
    margin-bottom: 1rem;
    color: var(--secondary-color);
}

/* About Section */
.about {
    padding: 6rem 0;
    background: var(--light-bg);
    position: relative;
}

.about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.about-text {
    text-align: center;
    max-width: 800px;
}

.about-text h2 {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
}

.about-text h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: var(--primary-color);
    transition: width 0.3s ease;
}

.about-text:hover h2::after {
    width: 100px;
}

.about-text p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 2rem;
}

.about-features {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    text-align: left;
}

.about-features li {
    position: relative;
    padding-left: 2rem;
    font-size: 1.1rem;
    color: #444;
    line-height: 1.6;
}

.about-features li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: var(--primary-color);
    font-weight: bold;
}

.about .btn {
    font-size: 1.1rem;
    padding: 0.8rem 2rem;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.about .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* CTA Section */
.cta {
    padding: 6rem 2rem;
    background: var(--primary-color);
    color: white;
    text-align: center;
}

.cta h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.cta p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    opacity: 0.9;
}

/* Screenshots Section */
.screenshots {
    padding: 6rem 0;
    background: white;
}

.screenshot-item {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-bottom: 6rem;
    padding: 0 2rem;
    position: relative;
    transition: all 0.4s ease;
    border-radius: 12px;
    overflow: hidden;
}

.screenshot-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        45deg,
        rgba(13, 110, 253, 0.2),
        transparent
    );
    opacity: 0;
    transition: opacity 0.4s ease;
    z-index: 1;
}

.screenshot-item:hover::before {
    opacity: 1;
}

.screenshot-item:hover {
    transform: scale(1.02);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.screenshot-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    overflow: hidden;
}

.screenshot-image img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.4s ease;
    transform-origin: center;
}

.screenshot-item:hover img {
    transform: scale(1.05);
}

.screenshot-content {
    flex: 1;
    position: relative;
    z-index: 2;
    padding: 2rem;
    transition: all 0.4s ease;
}

.screenshot-item:hover .screenshot-content {
    transform: translateY(-5px);
}

.screenshot-content h2 {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
}

.screenshot-content p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
}

.screenshot-item.reverse {
    flex-direction: row-reverse;
}

/* Mobile App Section */
.mobile-app {
    padding: 6rem 2rem;
    background: var(--light-bg);
}

.mobile-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.mobile-text {
    flex: 1;
}

.mobile-text h2 {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
}

.mobile-text p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
}

.mobile-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.mobile-image img {
    max-height: 600px;
    width: auto;
}

/* Sticky Navbar for Homepage */
.homepage .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(-100%);
    background: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.homepage .navbar.navbar-scrolled {
    opacity: 1;
    transform: translateY(0);
}

/* Remove default bg-light since we're using custom background */
.homepage .navbar.bg-light {
    background: transparent !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 3rem;
    }
    
    .hero-subtitle {
        font-size: 1.25rem;
    }
    
    .hero-cta {
        flex-direction: column;
    }
    
    .about-content {
        flex-direction: column;
    }
    
    .feature-card {
        margin: 0 1rem;
    }
    
    .screenshot-item,
    .screenshot-item.reverse {
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 4rem;
    }

    .screenshot-content {
        text-align: center;
    }

    .mobile-content {
        flex-direction: column;
        text-align: center;
    }

    .mobile-image img {
        max-height: 400px;
    }
}

/* Cookie Banner */
.cookie-banner {
    margin: 0;
    border-radius: 0;
    border: none;
}

/* Button styles */
.btn-primary {
    background: var(--primary-color);
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 6px;
    font-weight: 500;
    transition: background-color 0.2s ease;
}

.btn-primary:hover {
    background: #0951b5;
}

/* Responsive styles */
@media only screen and (max-width: 720px) {
    .pro-Align {
        flex-direction: column;
        padding: 0 1.5rem;
    }
    
    .sec3centerContent {
        width: 100%;
        margin-bottom: 2rem;
    }
    
    .masthead h1 {
        font-size: 2.5em;
        padding: 0 1.5rem;
    }
    
    .features-icons-item {
        margin: 1rem 1.5rem;
    }
}

html {
    scroll-behavior: smooth;
}