.affirmations-section .affirmations-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/* Positive Affirmations Section */
.affirmations-section .fondGris {
    background: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e9f2;
    margin-bottom: 2rem;
    transition: box-shadow 0.3s ease;
}

.affirmations-section .fondGris:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}

.affirmations-section h4.section-title {
    color: #2c3e50;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}
.affirmations-section .action-icon{
    font-size: 2em;
}

.affirmations-section .contentText {
    color: #4a5568;
    font-size: 1rem;
    line-height: 1.7;
    padding: 1.5rem;
    background: #f8fafc;
    border-radius: 6px;
    margin-top: 1rem;
    min-height: 60px;
    transition: all 0.3s ease;
    border: 1px solid #e5e9f2;
}

.affirmations-section .contentText:hover {
    background: #ffffff;
    border-color: #cbd5e1;
}

/* Journaling Section */
.journaling-section .journal-entries {
    display: grid;
    gap: 2rem;
    padding: 0.5rem;
}

.journaling-section .journal-date-group {
    background: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e9f2;
}

.journaling-section .journal-date {
    color: #2c3e50;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 0.75rem;
    text-align: center;
}

.journaling-section .journal-date::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 2px;
    background: #0d6efd;
}

.journaling-section .journal-entry-list {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 1rem;
}

.journaling-section .journal-entry {
    background: #ffffff !important;
    border-radius: 6px !important;
    border: 1px solid #e5e9f2 !important;
    transition: all 0.3s ease;
}

.journaling-section .journal-entry:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: #cbd5e1 !important;
}

.journaling-section .entry-content {
    padding: 1.5rem;
    color: #4a5568;
    font-size: 0.9375rem;
    line-height: 1.7;
    position: relative;
}

.journaling-section .entry-content p {
    margin: 0;
    padding-right: 0.5rem;
}

.journaling-section .delete-icon {
    color: #64748b;
    font-size: 1.9em;
    position: absolute !important;
    top: 0.75rem !important;
    right: 0.75rem !important;
    padding: 8px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
}

.journaling-section .delete-icon:hover {
    color: #dc3545;
    background: rgba(220, 53, 69, 0.1);
    transform: scale(1.1);
}

.journaling-section .add-button {
    background: #0d6efd;
    color: white !important;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-left: 1rem;
    font-size: 1.5em;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(13, 110, 253, 0.2);
}

.journaling-section .add-button:hover {
    background: #0b5ed7;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(13, 110, 253, 0.3);
}

/* Icons and Buttons */
.affirmations-section .text-primary,
.journaling-section .text-primary {
    color: #0d6efd !important;
    opacity: 0.9;
    transition: all 0.2s ease;
}

.affirmations-section .text-primary:hover,
.journaling-section .text-primary:hover {
    opacity: 1;
}

.affirmations-section .action-icon,
.journaling-section .action-icon {
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 6px;
    border-radius: 4px;
}

.journaling-section .edit-icon {
    color: #64748b;
}

.journaling-section .edit-icon:hover {
    color: #0d6efd;
    background: rgba(13, 110, 253, 0.1);
}

/* Modal Styles */
.affirmations-modal .modal-content {
    background: #ffffff;
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
}

.affirmations-modal .modal-header {
    border-bottom: 1px solid #e5e9f2;
    padding: 1.5rem 2rem;
}

.affirmations-modal .modal-body {
    padding: 2rem;
}

.affirmations-modal .modal-footer {
    border-top: 1px solid #e5e9f2;
    padding: 1.5rem 2rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .affirmations-section .fondGris {
        padding: 1.5rem;
    }

    .affirmations-section h4.section-title {
        font-size: 1.125rem;
    }

    .affirmations-section .contentText {
        padding: 1.25rem;
    }

    .journaling-section .journal-date-group {
        padding: 1.5rem;
    }

    .journaling-section .entry-content {
        padding: 1.25rem;
    }
}

@media screen and (max-width: 480px) {
    .affirmations-section .fondGris {
        padding: 1.25rem;
    }

    .affirmations-section .contentText {
        padding: 1rem;
    }

    .journaling-section .journal-date-group {
        padding: 1.25rem;
    }

    .journaling-section .entry-content {
        padding: 1rem;
    }
}
