.centering {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login .card-body{
    width: 20em;
}
.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#iconGoogle{
    width: 3em;
    background-color: rgba(213, 213, 213, 0.17);
    padding: 7px;
    border-radius: 100%;
}

.registration_form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.registration_form input{
    margin: .2em;
}

