.modern-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

.hero-section {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    color: white;
}

.hero-overlay h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.hero-description {
    font-size: 1.2rem;
    max-width: 800px;
    line-height: 1.6;
    margin: 0 auto;
}

.content-section {
    padding: 4rem 2rem;
    background: #f8f9fa;
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
    max-width: 1200px;
    margin: 0 auto 4rem auto;
}

.feature-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-card h3 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.feature-card p {
    color: #666;
    line-height: 1.6;
}

.contact-section {
    text-align: center;
    max-width: 800px;
    margin: 4rem auto 0 auto;
    padding: 2rem;
}

.contact-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.social-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.8rem 1.5rem;
    background: white;
    border-radius: 30px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.social-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    color: #007bff;
}

.cta-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
}

.cta-button:hover {
    background: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .hero-overlay h1 {
        font-size: 2.5rem;
    }
    
    .hero-description {
        font-size: 1rem;
    }
    
    .social-links {
        flex-direction: column;
        align-items: center;
    }
    
    .content-section {
        padding: 2rem 1rem;
    }
}