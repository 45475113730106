.chatbot-container {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
}

.chatbot-toggle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.chatbot-toggle:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.chatbot-window {
    position: absolute;
    bottom: 100px;
    right: 0;
    width: 650px;
    height: 800px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.95) translateY(20px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chatbot-window.open {
    opacity: 1;
    transform: scale(1) translateY(0);
}

.chatbot-window.close {
    opacity: 0;
    transform: scale(0.95) translateY(20px);
}

.chatbot-header {
    padding: 25px;
    background-color: #007bff;
    color: white;
    animation: slideDown 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chatbot-header h3 {
    margin: 0;
    font-size: 22px;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.chatbot-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.message {
    max-width: 85%;
    padding: 15px 22px;
    border-radius: 22px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.6;
    opacity: 0;
    transform: translateY(10px);
    animation: messageAppear 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes messageAppear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.message.user {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 5px;
    animation-delay: 0.1s;
}

.message.assistant {
    background-color: #f0f2f5;
    color: black;
    align-self: flex-start;
    border-bottom-left-radius: 5px;
    animation-delay: 0.2s;
}

.message p {
    margin: 0 0 10px 0;
}

.message p:last-child {
    margin-bottom: 0;
}

.message strong {
    font-weight: 600;
}

.message em {
    font-style: italic;
}

.message ol, .message ul {
    margin: 10px 0;
    padding-left: 20px;
}

.message li {
    margin: 5px 0;
}

.message code {
    background: rgba(0, 0, 0, 0.1);
    padding: 2px 5px;
    border-radius: 4px;
    font-family: monospace;
}

.message.user code {
    background: rgba(255, 255, 255, 0.2);
}

.message h1, .message h2, .message h3, .message h4, .message h5 {
    margin: 15px 0 10px 0;
    font-weight: 600;
}

.message h1 { font-size: 1.4em; }
.message h2 { font-size: 1.3em; }
.message h3 { font-size: 1.2em; }
.message h4 { font-size: 1.1em; }
.message h5 { font-size: 1em; }

.message blockquote {
    border-left: 3px solid #ccc;
    margin: 10px 0;
    padding-left: 15px;
    font-style: italic;
}

.message.user blockquote {
    border-left-color: rgba(255, 255, 255, 0.5);
}

.chatbot-input {
    padding: 25px;
    border-top: 1px solid #eee;
    display: flex;
    gap: 15px;
    animation: slideUp 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.chatbot-input input {
    flex-grow: 1;
    padding: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 30px;
    outline: none;
    font-size: 16px;
    transition: all 0.2s ease;
}

.chatbot-input input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.chatbot-input button {
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.chatbot-input button:hover {
    background-color: #0056b3;
}

.chatbot-input button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.typing-indicator {
    display: flex;
    gap: 5px;
    padding: 10px 15px;
}

.typing-indicator span {
    width: 10px;
    height: 10px;
    background-color: #90949c;
    border-radius: 50%;
    animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(2) { animation-delay: 0.3s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

/* Add responsive design for smaller screens */
@media (max-width: 700px) {
    .chatbot-window {
        width: calc(100vw - 80px);
        height: calc(100vh - 140px);
        bottom: 90px;
    }
    
    .chatbot-container {
        bottom: 20px;
        right: 20px;
    }
}
