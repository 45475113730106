/* Kanban Board Styles */
.react-kanban-board {
    display: flex !important;
    padding: 1.5rem 0;
    width: 100%;
    gap: 1.5rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.react-kanban-column {
    background: white !important;
    border-radius: 16px !important;
    padding: 1rem !important;
    min-width: 300px;
    width: calc(33.33% - 1rem) !important;
    box-shadow: 0 4px 12px rgba(13, 110, 253, 0.08);
    border: 1px solid rgba(13, 110, 253, 0.1);
}

.react-kanban-column-header {
    color: #0d6efd !important;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    text-align: center;
    border-bottom: 2px solid rgba(13, 110, 253, 0.1);
}

.react-kanban-card {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    margin: 0.75rem 0;
    border: 1px solid rgba(13, 110, 253, 0.1);
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(13, 110, 253, 0.03);
}

.react-kanban-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(13, 110, 253, 0.08);
    border-color: rgba(13, 110, 253, 0.2);
    background: linear-gradient(to right bottom, white, #f8fbff);
}

.react-kanban-card__title {
    color: #2c3e50;
    font-weight: 500;
    margin-bottom: 0.5rem;
    word-break: break-word;
}

.react-kanban-card__description {
    color: #3a4a5b;
    font-size: 0.9rem;
    word-break: break-word;
}

/* Card Actions */
.card-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid rgba(13, 110, 253, 0.1);
}

.card-actions button {
    background: none;
    border: none;
    color: #0d6efd;
    padding: 0.25rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0.7;
}

.card-actions button:hover {
    color: #0d6efd;
    transform: scale(1.1);
    opacity: 1;
}

/* Drag and Drop Styles */
.react-kanban-column-dragging {
    background: #f8fbff !important;
    box-shadow: 0 8px 24px rgba(13, 110, 253, 0.12) !important;
}

.react-kanban-card-dragging {
    background: white !important;
    box-shadow: 0 12px 24px rgba(13, 110, 253, 0.15) !important;
    transform: scale(1.02) !important;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .react-kanban-column {
        min-width: 280px;
    }
}

@media screen and (max-width: 768px) {
    .react-kanban-board {
        padding: 1rem 0;
        gap: 1rem;
    }

    .react-kanban-column {
        min-width: 260px;
        padding: 0.875rem !important;
    }

    .react-kanban-column-header {
        font-size: 0.9rem;
        padding: 0.4rem;
        margin-bottom: 0.875rem;
    }

    .react-kanban-card {
        padding: 0.875rem;
        margin: 0.6rem 0;
    }

    .react-kanban-card__title {
        font-size: 0.95rem;
    }

    .react-kanban-card__description {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 480px) {
    .react-kanban-column {
        min-width: 240px;
        padding: 0.75rem !important;
    }
}
