

#tab-1 th{
    min-width: 20em;
    text-align: center;
}
.fondGris{
    background: rgba(222, 226, 230, 0.25);
    padding: 1em;
    border-radius: .5em;
}
.react-kanban-column{
    background: rgba(222, 226, 230, 0.25) !important;
    border-radius: .5em !important;
}
.react-kanban-column-header{
    color: #0d6efd;
}
#tab-2 th{
    text-align: center;
    vertical-align: top;
    text-align: center;
    max-width: 2em !important;
    height: 2em;
}
.react-kanban-board{
    display: block !important;
}
.card-container {
    width: 100%;
}
.LogisticHeader .ql-editor{
    text-align: center;
    font-size: 1.4em;
}
#tab-2 td {
    max-width: 2em !important;
    text-wrap: normal;
}
.react-kanban-column{
    width: 32% !important;
    background: red;
}
.ant-card-body{
    padding: 3px !important;
}
.minHeightTask{
    min-height: 30em !important;
}
#tab-2 td{
    vertical-align: center;
    padding: .4em;
}
.react-kanban-card__title{
    word-break: break-word;
}
#tab-4 th{
    min-width: 15em;
    max-width: 15em;
    text-align: center;
    vertical-align: top;
}
#tab-4 .quill{
    max-width: 15em;
}
#tab-6 th{
    text-align: center;
}
#tab-6 td{
    max-width: 15em;
}
#tab-7 th{
    text-align: center;
}
.jaoYbx{
    border: none;
    width: 100%;
}
#tab-10 th{
    text-align: center;
    vertical-align: top;
}
textarea.form-control:focus {
    box-shadow: none;
}

.contentText{
    white-space: pre-line;
    word-break: break-word;
}


.introText{
    font-style: italic;
    font-size: .85em;
}
.p-autocomplete-panel{
    z-index: 1200 !important;
}
.custom-calendar-container td{
    border: none !important;
}
.calendar_default_event_inner{
    font-weight: 700;
}
.subtitleMaj{
    font-size: 0.75em;
    font-weight: 400;
}

.p-autocomplete-dropdown{
    background:#0d6efd;
    border:#0d6efd;
    border-radius: 0em 0.4em 0.4em 0;
}
.p-autocomplete-item{
    position: relative;
    left: -1em;
}
.btnChoiceLogistics{
    height: 200px;
    margin: 1em;
    width: 12em;
}

th{
    border: 1px solid gray !important;
    background: #f8f8f8 !important;
}
td{
    border: 1px solid gray !important;
}
.tab-pane.container .table-responsive:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    height: .3em !important;
}

.quill{
    height: 100% !important;
}
.ql-toolbar{
    display: none;
}
.ql-container{
    border: none !important;
}
.ql-editor{
    font-size: 1.1em ;
}
.btnDeleteTableTr{
    margin-top: 2em;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.74); /* Couleur de fond foncée avec une opacité de 0.5 */
}

.mainTabs a.nav-link.active {
    background: rgba(155, 155, 155, 0.34) !important;
    color: black;
    font-weight: bold;
}
.mainTabs .nav-item {
    border: 1px solid rgb(221, 221, 221);
}

.mainCharts .recharts-default-legend{
    position: relative;
    top: 4em;
}
.totalChart .legend-item-0{
    display: none !important;
}

.navigator_default_todaybox{
    border: solid 1px #0d6efd !important;
}