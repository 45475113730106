.pricing-card {
    position: relative;
    overflow: hidden;
}

.glitter {
    position: absolute;
    width: 4px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    pointer-events: none;
}

.glitter::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    background: inherit;
}

@keyframes glitter1 {
    0%, 100% { transform: translate(0, 0) scale(0); opacity: 0; }
    25% { transform: translate(100px, -50px) scale(1); opacity: 0.8; }
    50% { transform: translate(200px, -100px) scale(0); opacity: 0; }
}

@keyframes glitter2 {
    0%, 100% { transform: translate(0, 0) scale(0); opacity: 0; }
    25% { transform: translate(-150px, -80px) scale(1); opacity: 0.8; }
    50% { transform: translate(-300px, -160px) scale(0); opacity: 0; }
}

@keyframes glitter3 {
    0%, 100% { transform: translate(0, 0) scale(0); opacity: 0; }
    25% { transform: translate(80px, 60px) scale(1); opacity: 0.8; }
    50% { transform: translate(160px, 120px) scale(0); opacity: 0; }
}

.pricing-card .glitter:nth-child(3n + 1) {
    animation: glitter1 4s infinite;
}

.pricing-card .glitter:nth-child(3n + 2) {
    animation: glitter2 6s infinite;
}

.pricing-card .glitter:nth-child(3n + 3) {
    animation: glitter3 5s infinite;
}

.pricing-card.featured .glitter {
    background: rgba(13, 110, 253, 0.5);
}

.hover-elevate {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-elevate:hover {
    transform: translateY(-5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
