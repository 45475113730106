.welcome-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.welcome-header {
    text-align: center;
    margin-bottom: 60px;
}

.welcome-header h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 700;
}

.welcome-subtitle {
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin-bottom: 60px;
}

.feature-card {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    width: 80px;
    height: 80px;
    background: #f8f9fa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    color: #0d6efd;
    transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
    background: #0d6efd;
    color: white;
}

.feature-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
}

.feature-card p {
    color: #666;
    line-height: 1.6;
}

.getting-started-section {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    padding: 40px 20px;
    background: #f8f9fa;
    border-radius: 10px;
}

.getting-started-section h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.getting-started-section p {
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
}

.start-button {
    background: #0d6efd;
    color: white;
    border: none;
    padding: 15px 40px;
    font-size: 1.1rem;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.start-button:hover {
    background: #0b5ed7;
}

@media (max-width: 768px) {
    .welcome-header h1 {
        font-size: 2.5rem;
    }

    .features-grid {
        grid-template-columns: 1fr;
    }

    .getting-started-section {
        padding: 30px 15px;
    }
}
