.admin-container {
    padding: 0 2rem 0 2rem;
    background: #f8f9fa;
    min-height: 100vh;
}

.admin-header {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.admin-header h1 {
    color: #2c3e50;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.admin-header p {
    color: #6c757d;
    margin-bottom: 0;
}

.admin-section {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow-x: auto;
}

.admin-section h2 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e9ecef;
}

.users-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.users-table th,
.users-table td {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    white-space: nowrap;
}

.users-table td small.text-muted {
    font-family: monospace;
    font-size: 0.85em;
    color: #6c757d;
}

.users-table td:nth-child(4) {
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
}

.users-table th {
    background: #f8f9fa;
    font-weight: 600;
    text-align: left;
    color: #495057;
    position: sticky;
    top: 0;
    z-index: 1;
}

.users-table tr:hover {
    background-color: #f8f9fa;
}

.status-active {
    color: #28a745;
    font-weight: 500;
    padding: 4px 8px;
    background-color: rgba(40, 167, 69, 0.1);
    border-radius: 4px;
}

.status-expired {
    color: #dc3545;
    font-weight: 500;
    padding: 4px 8px;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 4px;
}

.status-no-subscription {
    color: #6c757d;
    font-weight: 500;
    padding: 4px 8px;
    background-color: rgba(108, 117, 125, 0.1);
    border-radius: 4px;
}

.edit-button {
    background: #0d6efd;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
}

.edit-button:hover {
    background: #0b5ed7;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.modal-header {
    margin-bottom: 1.5rem;
}

.modal-header h3 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: 500;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
}

.form-control:focus {
    border-color: #0d6efd;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(13,110,253,0.25);
}

.feedback-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-left: 4px solid #0d6efd;
}

.feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.feedback-user {
    font-weight: 500;
    color: #2c3e50;
}

.feedback-date {
    color: #6c757d;
    font-size: 0.9rem;
}

.feedback-content {
    color: #495057;
    line-height: 1.6;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.stat-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-number {
    font-size: 2rem;
    font-weight: 700;
    color: #0d6efd;
    margin-bottom: 0.5rem;
}

.stat-label {
    color: #6c757d;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Modal Overlay Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-dialog {
    background: white;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
}
