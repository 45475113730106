.calendar_default_event_inner {
    background: #2e78d6;
    color: #fff;
    border: none;
    border-radius: .8em;
    font-size: 10pt;
    padding: 5px;
    opacity: 0.9;
}
.calendar_default_event :nth-child(3){
    font-weight: bold;
}
.modal_default_buttons{
    display: flex;
    align-items: end;
    justify-content: end;
}
.today-header {
    border: 2px solid red; /* ou n'importe quelle autre propriété CSS */
}


.modal_default_buttons button{
    border: none;
    padding-top: .4em;
    padding-bottom: .4em;
    border-radius: .4em;
    margin: .2em;
}

.modal_default_main{
    border: none;
    border-radius: .3em;
}

.modal_default_content{
    font-size: 1.8em;
}

.modal_default_ok{
    background-color: #0d6efd;
    color: white;
}

.custom-calendar-container {
    height: 100%;
}

.deleteBtnLogistics{
    font-size: 1.4em;
    cursor: pointer;
    position: absolute;
    top: .3em;
    right: .3em;
    z-index: 3;
}

.containerCalendar{
    margin-left: 10px;
    display: flex;
}

@media only screen and (max-width: 720px) {
    .containerCalendar{
        flex-direction: column !important;
        align-items: center;
    }
    .custom-calendar-container{
        margin-top: 1em;
    }
}