/* Tasks Table Styles */
.tableTasks {
    min-height: 30em;
    border-collapse: separate;
    border-spacing: 0;
    margin: 1.5rem auto;
    background: white;
    box-shadow: 0 8px 24px rgba(13, 110, 253, 0.08);
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid rgba(13, 110, 253, 0.1);
}

.tableTasks thead th {
    background: white !important;
    color: #0d6efd !important;
    padding: 1.25rem 0.75rem !important;
    font-weight: 600 !important;
    border: none !important;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
    white-space: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 0.85rem;
    border-bottom: 2px solid rgba(13, 110, 253, 0.2) !important;
    box-shadow: 0 4px 12px rgba(13, 110, 253, 0.05);
    min-width: 250px;
    line-height: 1.4;
    height: auto;
}

.tableTasks tbody td {
    padding: 1.25rem !important;
    vertical-align: top !important;
    background: white !important;
    border: none !important;
    border-right: 1px solid rgba(13, 110, 253, 0.1) !important;
    min-width: 250px;
    max-width: 350px;
}

.tableTasks tbody td:last-child {
    border-right: none !important;
}

.tableTasks .d-flex.justify-content-between {
    background: white;
    border-radius: 12px;
    padding: 1rem 1.25rem;
    margin: 0.75rem 0;
    border: 1px solid rgba(13, 110, 253, 0.1);
    transition: all 0.3s ease;
    word-break: break-word;
    box-shadow: 0 2px 4px rgba(13, 110, 253, 0.03);
}

.tableTasks .d-flex.justify-content-between:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(13, 110, 253, 0.08);
    border-color: rgba(13, 110, 253, 0.2);
    background: linear-gradient(to right bottom, white, #f8fbff);
}

.tableTasks .todo-icon,
.tableTasks .doing-icon,
.tableTasks .done-icon {
    font-size: 1.2rem;
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
    opacity: 0.8;
    transition: opacity 0.2s ease;
}

.tableTasks .todo-icon:hover,
.tableTasks .doing-icon:hover,
.tableTasks .done-icon:hover {
    opacity: 1;
}

.tableTasks .text-danger {
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    opacity: 0.6;
}

.tableTasks .text-danger:hover {
    color: #dc3545 !important;
    transform: scale(1.1);
    opacity: 1;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .tableTasks {
        margin: 1rem -1rem;
        border-radius: 12px;
        box-shadow: 0 4px 16px rgba(13, 110, 253, 0.06);
    }
    
    .tableTasks tbody td {
        padding: 1rem !important;
        min-width: 200px;
    }
    
    .tableTasks thead th {
        padding: 1rem !important;
        font-size: 0.85rem;
    }
    
    .tableTasks .d-flex.justify-content-between {
        padding: 0.875rem 1rem;
        font-size: 0.9rem;
    }
    
    .tableTasks .todo-icon,
    .tableTasks .doing-icon,
    .tableTasks .done-icon {
        font-size: 1rem;
        margin-right: 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .tableTasks tbody td {
        min-width: 180px;
    }
    
    .tableTasks thead th {
        padding: 0.875rem 0.75rem !important;
    }
}
