.dailyRoutineTable .table > tbody {
    vertical-align: middle !important;
}

.centeredTd{
    text-align: -webkit-center;
}
.dailyRoutineTable .form-control{
    display: inline !important;
}

.dailyRoutineTable .form-select{
    display: inline !important;
}


h5{
    color: #0d6efd !important;
}

.leftTd{
    max-width: 10em;
}

.dailyRoutineTable .table{
    width: 90%;
}
.flowBlockTdTitle{
    font-size: 1.5em !important;
    text-align: center;
}

.btnSave{
    padding: .5em 2em .5em 2em !important;
    position: relative;
}

.btnTimeContainer{
    width: 10em !important;
}

/* Add this CSS in your component or a separate CSS file */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.modal-content p {
    margin-bottom: 100px;
}


.dailyRoutineTable .ql-editor{
    max-width: 12em;
}
.dailyRoutineTable .quill{
    height: 100%;
}