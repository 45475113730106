#page-container {
    position: relative;
    min-height: 100vh;
}

.mainContainer{
    max-width: 100% !important;
}

.containerDashboardContent{
    width: 100% !important;
}

.custom-textarea {
    resize: vertical; /* Permettre la redimension verticale si nécessaire */
    border: 1px solid #ccc; /* Ajouter une bordure pour mieux distinguer la zone de texte */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}
