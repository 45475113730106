:root {
    --primary-color: #0d6efd;
    --secondary-color: #2c3e50;
    --dark-bg: #2c3e50;
}

.register-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: linear-gradient(135deg, 
        #f8f9fa 0%,
        #0d6efd 100%
    );
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.register-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.register-header {
    text-align: center;
    margin-bottom: 2rem;
}

.register-header h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.register-header p {
    color: #666;
    font-size: 1rem;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
}

.form-group input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    outline: none;
    border-color: #0d6efd;
}

.form-group .error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.register-button {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 5px;
    background-color: #0d6efd;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.register-button:hover {
    background-color: #0b5ed7;
}

.register-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.forgot-password-button {
    background: none;
    border: none;
    color: #0d6efd;
    padding: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-top: 0.5rem;
}

.forgot-password-button:hover {
    color: #0b5ed7;
    text-decoration: underline;
}

.forgot-password-button:disabled {
    color: #ccc;
    cursor: not-allowed;
    text-decoration: none;
}

.login-link {
    text-align: center;
    margin-top: 2rem;
    color: #666;
}

.login-link a {
    color: #0d6efd;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.login-link a:hover {
    color: #0b5ed7;
    text-decoration: underline;
}
